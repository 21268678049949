const Template = {
  Stylesheet: `<style name="layers-auth-dialog">
    .layers--locked {
      overflow: hidden !important;
      pointer-events: none !important;
    }

    layers-auth-dialog {
      --color-primary-rgb: 0, 184, 173;

      display: flex;
      align-items: center;
      justify-content: center;

      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 64px;

      pointer-events: all;
      background: rgba(var(--color-primary-rgb), 0.7);
      z-index: 9999999999999;
      animation: layers--fade-in 0.5s forwards;
      perspective: 2000px;
    }

    .layers-auth-dialog__content {
      position: relative;
      background: white;
      border-radius: 8px;
      overflow: hidden;
      height: 640px;
      min-height: 300px;
      max-height: 100vh;

      width: 420px;
      min-width: 260px;
      max-width: 100vw;

      opacity: 0;
      animation: ease-in-out layers--fade-in 0.3s 0.5s forwards;
    }

    .layers-auth-dialog__iframe {
      border: none;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .layers-auth-dialog__close-button {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 8px;
      right: 8px;
      width: 42px;
      height: 42px;

      background: white;
      border-radius: 50px;
      font-size: 24px;
      cursor: pointer;
      color: rgb(0,0,0,0.6);
      font-weight: bold;
    }

    .layers-auth-dialog__close-button:hover {
      color: rgba(var(--color-primary-rgb), 0.9);
    }

    .layers--hidden {
      display: none;
    }

    @keyframes layers--fade-in {
      0% { opacity: 0; }
      100% {opacity: 1; }
    }
    @keyframes layers--fade-out {
      0% { opacity: 1; }
      100% { opacity: 0; }
    }

    @keyframes layers--card-exit {
      0% { opacity: 1; transform: initial; }
      100% { opacity: 0; transform: translateZ(-100px); }
    }

    @media only screen and (min-width: 600px) {
      layers-auth-dialog .layers-auth-dialog__content {
        animation-duration: 1.4s;
        animation-name: layers--card-enter;
      }

      @keyframes layers--card-enter {
        0% {
          opacity: 0;
          transform: translateY(-50px) translateZ(80px) rotateX(-25deg);
        }
        20% {
          opacity: 1;
        }
        25% {
          transform: rotateX(7deg);
        }
        45% {
          transform: rotateX(-2deg);
        }
        65% {
          transform: initial;
          box-shadow: none;
        }
        100% {
          opacity: 1;
          box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072);
          ;
        }
      }
    }


    @media only screen and (max-width: 600px) {
      layers-auth-dialog {
        padding: 0;
      }

      layers-auth-dialog .layers-auth-dialog__content {
        border-radius: 0px;
        width: 100vw;
        min-width: auto;
        max-width: auto;

        height: 100vh;
        min-height: auto;
        max-height: auto;
      }
    }
  </style>
  `,

  Element: `
    <div class="layers-auth-dialog__content">
      <iframe class="layers-auth-dialog__iframe layers--hidden"></iframe>
      <div class="layers-auth-dialog__close-button">×</div>
    </div>
  `,
};

class LayersAuthDialog extends HTMLElement {
  constructor(url) {
    super();

    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    this.src = url;
    LayersAuthDialog.injectStylesheet();
    this.render();
  }

  get promise() {
    return this._promise;
  }

  resolve(value) {
    this._resolve?.(value);
    this._resolve = this._reject = null;
  }

  reject(error) {
    try {
      this._reject?.(error);
    } catch (e) {}
    this._resolve = this._reject = null;
  }

  dismiss() {
    if (this.isDismissed) {
      return;
    }

    if (this._reject) {
      this.resolve(null);
    }

    this.isDismissed = true;
    this.content.style.animationDelay = "0s";
    this.content.style.animationDuration = "0.5s";
    this.content.style.animationName = "layers--card-exit";
    this.style.animationDelay = "0.1s";
    this.style.animationDuration = "0.6s";
    this.style.animationName = "layers--fade-out";
    this.onanimationend = () => {
      this.remove();
    };
  }

  render() {
    if (this._rendered) {
      return;
    }
    this._rendered = true;
    this.innerHTML = Template.Element;
    this.content = this.querySelector(".layers-auth-dialog__content");
    this.addEventListener("click", (e) => {
      if (e.target === this) {
        this.dismiss();
      }
    });

    this.iframe = this.querySelector(".layers-auth-dialog__iframe");

    this.closeButton = this.querySelector(".layers-auth-dialog__close-button");
    this.closeButton.addEventListener("click", () => {
      this.dismiss();
    });
  }

  loadIframe() {
    if (this.loadedIframe) return;
    this.loadedIframe = true;
    this.iframe.src = this.src;
    this.iframe.onload = () => {
      this.iframe.style.animation = "layers--fade-in 0.5s forwards";
    };
    this.iframe.classList.remove("layers--hidden");
  }

  connectedCallback() {
    document.body.classList.add("layers--locked");
    setTimeout(() => {
      this.loadIframe();
    }, 1000);
  }

  disconnectedCallback() {
    document.body.classList.remove("layers--locked");
  }

  static injectStylesheet() {
    if (this.injectedStylesheet) {
      return;
    }
    document.head.insertAdjacentHTML("beforeend", Template.Stylesheet);
    this.injectedStylesheet = true;
  }

  static dismiss() {
    let existing = document.querySelector("layers-auth-dialog");
    if (existing) {
      existing.dismiss();
    }
  }

  static launch(url) {
    let existing = document.querySelector("layers-auth-dialog");
    if (existing) {
      return existing;
    }

    let element = new LayersAuthDialog(url);
    document.body.insertAdjacentElement("beforeend", element);
    return element;
  }
}

window.LayersAuthDialog = LayersAuthDialog;
try {
  customElements.define("layers-auth-dialog", LayersAuthDialog);
} catch (e) {
  window.location.reload();
}

export default LayersAuthDialog;
