import LayersAuthDialog from "./layers-auth-dialog";

const Template = {
  Stylesheet: `<style name="layers-auth-button">
    layers-auth-button {
      font-family: 'Nunito', sans-serif;
      --color-primary-rgb: 0, 184, 173;
      --color-primary: rgb(0, 184, 173);
      --color-dark: rgb(80, 80, 80);
      --color-light: rgb(255, 255, 255);
      --color-dark80: rgba(0, 0, 0, 0.8);
      --color-layers: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABGdBTUEAALGPC/xhBQAAARhpQ0NQSUNDIFByb2ZpbGUAACiRY2BgUkgsKMhhEmBgyM0rKQpyd1KIiIxSYH/CwAqE/Aw8DEaJycUFjgEBPgxAAKNRwbdrDIwg+rIuyCxMebyAKyW1OBlI/wHi7OSCohIGBsYMIFu5vKQAxO4BskWSssHsBSB2EdCBQPYWEDsdwj4BVgNh3wGrCQlyBrI/ANl8SWA2E8guvnQIWwDEhtoLAoKOKflJqQog32sYWlpaaJLoB4KgJLWiBEQ75xdUFmWmZ5QoOAJDKlXBMy9ZT0fByMDIgIEBFO4Q1Z8DweHJKHYGIYYACLE5EgwM/ksZGFj+IMRMehkYFugwMPBPRYipGTIwCOgzMOybk1xaVAY1hpHJmIGBEB8AES5KTSIkBkUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAHhlWElmTU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAKgAgAEAAAAAQAAAAGgAwAEAAAAAQAAAAEAAAAARdB4TwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAA1JREFUCB1jYNix9j8ABIYCZbCnDX8AAAAASUVORK5CYII=");
      --layers-logo: url("data:image/svg+xml,%3Csvg width='168' height='168' viewBox='0 0 168 168' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.55' fill-rule='evenodd' clip-rule='evenodd' d='M55.5341 27.7269C55.5341 13.6427 45.0879 2.08925 31.5627 0.218689C32.9922 0.218689 34.4217 0.218689 35.7412 0.328722C42.7787 0.328722 49.8162 0.878886 56.7437 1.97922C56.7608 1.97922 56.7753 1.98188 56.7879 1.98679C67.2172 3.63876 77.2083 6.60684 86.9801 10.672C102.595 17.2739 116.669 26.7368 128.765 38.8404C140.861 50.944 150.317 65.0282 156.915 80.6529C161.078 90.2987 163.931 100.272 165.583 110.573C165.627 110.613 165.671 110.653 165.716 110.693C166.815 117.625 167.365 124.667 167.365 131.819V131.82C167.365 133.58 167.365 135.34 167.255 137.101C166.045 122.796 154.06 111.683 139.545 111.683H109.086C108.106 107.871 106.803 104.274 105.177 100.679H105.124C101.385 92.3164 96.1069 84.7241 89.5092 78.1221C82.8016 71.4101 74.9944 66.1285 66.6374 62.3874V62.276C63.0096 60.6261 59.3818 59.3061 55.5341 58.3161V27.7269ZM109.086 100.68V100.679H139.541C139.678 100.679 139.815 100.68 139.952 100.681C139.853 100.68 139.754 100.68 139.655 100.68H109.086Z' fill='%23ffffff'/%3E%3Cpath d='M167.25 137.101C166.04 122.907 154.055 111.683 139.54 111.683H109.081H64.8767C59.7086 111.683 55.6401 107.502 55.6401 102.441V58.4275V27.8383C55.6401 13.7541 45.1938 2.20066 31.6686 0.330099C30.3491 0.110033 29.0296 0 27.82 0C25.071 0 22.432 0.440131 19.9029 1.10033C8.35701 4.51135 0 15.1845 0 27.8383V64.6993V139.522C0 154.926 12.4255 167.36 27.82 167.36H103.033H139.54C152.625 167.36 163.731 158.227 166.59 146.014C167.03 143.923 167.36 141.832 167.36 139.632C167.36 138.751 167.25 137.871 167.25 137.101Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");

      --padding-y: 10px;
      --padding-x: 16px;
      --border-radius: 4px;

      display: inline-flex;
      padding: var(--padding-y) var(--padding-x);
      cursor: pointer;

      border-radius: var(--border-radius);
      border: 1px solid transparent;

      line-height: var(--content-height);

      color: var(--color-content);
      background: var(--color-background);
    }

    @supports (color: color(display-p3 0 0.72 0.679)) {
      layers-auth-button {
        --color-primary: color(display-p3 0 0.72 0.679);
        --color-layers: var(--color-primary);
      }
    }

    /* Block mode */
    layers-auth-button[block] {
      display: flex;
      justify-content: center;
    }

    /* Inherits the font */
    layers-auth-button[inherit-font] {
      font-family: inherit;
    }

    /* Mode variation: Light | Dark */
    layers-auth-button:not([light]),
    layers-auth-button {
      --color-content: var(--color-primary);
      --color-background: var(--color-light);
      --layers-logo-color: var(--color-layers);
    }

    layers-auth-button[dark] {
      --color-content: var(--color-light);
      --color-background: var(--color-layers);
      --layers-logo-color: var(--color-light);
    }

    /* Size variation: Small | Normal | Large */
    layers-auth-button[size=small] {
      --content-height: 18px;
      --padding-y: 8px;
      --padding-x: 14px;
      font-size: 14px;
    }

    layers-auth-button[size=normal],
    layers-auth-button {
      --content-height: 20px;
      --padding-y: 10px;
      --padding-x: 16px;
      font-size: 16px;
    }

    layers-auth-button[size=medium] {
      --content-height: 24px;
      --padding-y: 12px;
      --padding-x: 20px;
      font-size: 18px;
    }

    layers-auth-button[size=large] {
      --content-height: 28px;
      --padding-y: 14px;
      --padding-x: 24px;
      font-size: 20px;
    }

    /* Raised variation */
    layers-auth-button[raised] {
      box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 -3px 8px rgba(0, 0, 0, 0.04);
    }

    /* Flat mode (no border) */
    layers-auth-button:not([flat]) {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    /* Logo inclusion */
    layers-auth-button:before {
      content: ' ';
      --logo-size: max(var(--content-height), 16px);
      display: inline-block;
      vertical-align: middle;
      width: var(--logo-size);
      height: var(--logo-size);
      margin-right: 16px;
      background: var(--layers-logo-color);
      mask-image: var(--layers-logo);
      -webkit-mask-image: var(--layers-logo);
      mask-size: 100% 100%;
      -webkit-mask-size: 100% 100%;
    }

    /* Hover effect */
    layers-auth-button[animate=true] {
      transition: opacity 0.2s, background 0.2s, border-color 0.2s;
    }
    layers-auth-button[dark]:hover {
      opacity: 0.8;
    }
    @supports (color: color(display-p3 0 0.72 0.679)) {
      layers-auth-button[animate=true] {
        transition: opacity 0.2s;
      }
      layers-auth-button:not([dark]):hover {
        background: rgba(var(--color-primary-rgb), 0.05);
      }
      layers-auth-button:not([dark]):not([flat]):hover {
        border-color: rgba(var(--color-primary-rgb), 0.4);
      }
    }
    @supports not (color: color(display-p3 0 0.72 0.679)) {
      layers-auth-button:not([dark]):hover {
        background: rgba(var(--color-primary-rgb), 0.05);
      }
      layers-auth-button:not([dark]):not([flat]):hover {
        border-color: rgba(var(--color-primary-rgb), 0.4);
      }
    }
    </style>
  `,

  Font: `<style name="layers-auth-button-font">
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
    </style>
  `,

  Element: `
    <span class="layers-auth-button__text"></span><strong>&nbsp;Layers</strong>
  `,
};

class LayersAuth extends HTMLElement {
  constructor() {
    super();

    this.addEventListener("click", this.launch.bind(this));
  }

  connectedCallback() {
    LayersAuth.injectStylesheet();

    LayersAuth.properties.forEach((prop) => {
      if (!this.hasAttribute(prop.attribute) && prop.default !== undefined) {
        this.setAttribute(prop.attribute, prop.default);
      }

      if (prop.property === undefined) return;

      Object.defineProperty(this, prop.property, {
        get: () => {
          return this.getAttribute(prop.attribute);
        },
        set: (val) => {
          this.setAttribute(prop.attribute, val);
        },
      });
    });
    this.render();
  }

  render() {
    if (!this.hasAttribute("inherit-font")) {
      LayersAuth.injectLayersFont();
    }

    this.innerHTML = Template.Element;
    this.querySelector(".layers-auth-button__text").textContent =
      this.getAttribute("label");
  }

  static injectStylesheet() {
    if (this.injectedStylesheet) {
      return;
    }
    document.head.insertAdjacentHTML("beforeend", Template.Stylesheet);
    this.injectedStylesheet = true;
  }
  static injectLayersFont() {
    if (this.injectedLayersFont) {
      return;
    }
    document.head.insertAdjacentHTML("beforeend", Template.Font);
    this.injectedLayersFont = true;
  }

  static get properties() {
    return [
      {
        attribute: "client-id",
        property: "client_id",
        required: true,
      },
      {
        attribute: "redirect-uri",
        property: "redirect_uri",
        default: window.location ? String(window.location.origin) : undefined,
        required: true,
      },
      {
        attribute: "scope",
        property: "scope",
        required: true,
      },
      {
        attribute: "response-type",
        property: "response_type",
        default: "code",
        required: true,
      },
      {
        attribute: "state",
        property: "state",
      },
      {
        attribute: "mode",
        property: "mode",
        default: "redirect",
      },
      {
        attribute: "label",
        default: "Continuar com",
        required: false,
        observed: true,
      },
      {
        attribute: "inherit-font",
        observed: true,
      },
    ];
  }

  static get observedAttributes() {
    return this.properties
      .filter((prop) => prop.observed)
      .map((prop) => prop.attribute);
  }

  static buildOAuthUrl(params) {
    let link = new URL("https://id.layers.digital/");

    let missingProps = [];
    for (let prop of this.properties) {
      if (prop.required && !params[prop.property]) {
        missingProps.push(prop.property);
      }
    }
    if (missingProps.length > 0) {
      throw new Error(
        "Algumas propriedades estão inválidas para iniciar a autenticação com Layers: " +
          missingProps.join()
      );
    }

    link.searchParams.set("client_id", params.client_id);
    link.searchParams.set("response_type", params.response_type);
    link.searchParams.set("scope", params.scope);
    if (params.state) {
      link.searchParams.set("state", params.state);
    }
    link.searchParams.set("display", params.mode);
    if (params.mode === "embedded" || params.mode === "popup") {
      link.searchParams.set("redirect_uri", window.location.origin);
    } else {
      link.searchParams.set("redirect_uri", params.redirect_uri);
    }

    return String(link);
  }

  static launch(props) {
    try {
      let url = this.buildOAuthUrl(props);

      if (!props.mode || props.mode === "redirect") {
        window.location = url;
      } else if (props.mode === "embedded") {
        LayersAuthDialog.launch(url);
      } else if (props.mode === "popup") {
        let width = 500;
        let height = 600;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const popupWindow = window.open(
          url,
          "layers-auth-dialog",
          "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            width +
            ", height=" +
            height +
            ", top=" +
            top +
            ", left=" +
            left
        );
        return { popupWindow };
      } else {
        window.alert("Modo de fluxo de Autenticação inválido: " + props.mode);
      }
    } catch (e) {
      console.log(e.stack);
      window.alert(e);
    }
  }

  static onResult(listener) {
    LayersAuth.listeners = LayersAuth.listeners || [];
    LayersAuth.listeners.push(listener);
  }

  launch(evt) {
    const launchData = LayersAuth.launch(this) || {};

    if (this.injectedMessageListener) return;

    window.addEventListener("message", (event) => {
      let data;
      try {
        data = JSON.parse(event.data);
        if (!data.layers) {
          return;
        }
        delete data.layers;
      } catch (e) {
        return;
      }

      const listeners = LayersAuth.listeners || [];
      const error = data.error;
      data = !error ? data : null;
      for (const listener of listeners) {
        listener(error, data);
      }
      if (listeners.length === 0) {
        console.warn(
          "[LayersAuth] Você deve usar `LayersAuth.onResult(...)` para receber os dados de autenticação"
        );
      }

      LayersAuthDialog.dismiss();
      if (launchData.popupWindow) {
        launchData.popupWindow.close();
      }
    });
    this.injectedMessageListener = true;
  }

  attributeChangedCallback(name, oldVal, newVal) {
    this.render();
  }
}

window.LayersAuth = LayersAuth;
try {
  customElements.define("layers-auth-button", LayersAuth);
} catch (e) {
  window.location.reload();
}
